import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import {Helmet} from "react-helmet";

const PuckProductPage = () => (
  <Layout pageInfo={{ pageName: "product/puck" }}>
    <Seo title="Puck" keywords={[ `Product`, `Puck`, `Anleitung`, `TrackSecure`, `FAQ` , `Manual` ]} />
    <h1 className="heading-4">Puck</h1>
    <p className="content-text">
        Du wirst zum Servicebereich des Pucks umgeleitet...
    </p>
    <Helmet>
        <meta http-equiv="Refresh" content="0; URL=https://tracksecure.atlassian.net/servicedesk/customer/portal/5"/>
    </Helmet>
  </Layout>
)

export default PuckProductPage
